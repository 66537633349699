import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Maybe, User } from '../../../base-types.gql-gen';
import { IonAvatar, IonButton, IonButtons, IonIcon } from '@ionic/angular/standalone';
import { callPhoneNumber } from '../../utils';

@Component({
  selector: 'app-community-user-card',
  templateUrl: 'community-user-card.component.html',
  styleUrls: ['./community-user-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonAvatar, IonButtons, IonButton, IonIcon],
})
export class CommunityUserCardComponent {
  @Input() user?: Maybe<User> | undefined;

  constructor() {}

  callToUs(event: Event) {
    event.stopPropagation();
    const phone = this.user?.phone;
    const phoneNumber = `+${phone}`;
    callPhoneNumber(phoneNumber);
  }
}
