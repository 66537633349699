<div class="card">
  <div class="main">
    <div class="image-container">
      <div class="image">
        @if (machine.photos && machine.photos.length) {
          <img src="{{ machine.photos[0]?.src }}?w=300&h=300" />
        } @else {
          <div class="after">
            нет
            <br />
            фото
          </div>
        }
      </div>
    </div>
    <div class="machine-info">
      <div class="name" [class.verified]="machine.verificationStatus === 'approved'">
        <div class="model-name">
          {{ machine.model }}
        </div>
        <ion-icon
          class="model"
          name="{{ machine.verificationStatus === 'approved' ? 'checkmark-circle' : 'warning' }}"
        ></ion-icon>
      </div>
      @if (myCompanyMachine) {
        <div class="info-label reg-number">{{ machine.reg }}</div>
      }
      <div class="info-label">{{ machine.year }} г.</div>
    </div>
  </div>
</div>
