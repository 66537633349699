import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as translations from '../../interface.json';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonNote,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
  LoadingController,
  ModalController,
} from '@ionic/angular/standalone';
import { FormsModule } from '@angular/forms';
import { AddressPickFieldComponent } from '../entry-details-machine/address-pick-field/address-pick-field';
import { EntryParamsTextComponent } from '../entry-details-machine/params-text/entry-params-text.component';
import {
  Entry,
  EntryObjects,
  EnumEntryRequestsConditionsWorkType,
  EnumEntryRequestsPaymentType,
  MachineType,
  MachineTypeParams,
  Maybe,
} from '../../base-types.gql-gen';
import dayjs, { Dayjs } from 'dayjs';
import * as uuidGen from 'uuid';
import { Api } from '../apollo/api';
import { AddressNewComponent } from '../entry-details-machine/address-new/address-new';
import { EntryParamsComponent } from '../entry-details-machine/entry-params/entry-params.component';

const unitOptions = [
  { id: 'shift', name: 'Количество смен' },
  { id: 'trip', name: 'Количество рейсов' },
  { id: 'cbm', name: 'Количество кубов' },
];

export interface InfoForUpdate {
  workDays: number;
  date?: Dayjs;
  calendarDate: string | undefined;
  unit: EnumEntryRequestsConditionsWorkType;
  categoryParams: MachineTypeParams[] | null;
  paymentType: EnumEntryRequestsPaymentType;
  address: any;
  previousAddresses: any[];
  price: number;
  comment: string;
  objects: Maybe<EntryObjects>[];
  entryParams: any[];
}

@Component({
  selector: 'app-fields-edit',
  templateUrl: 'fields-edit.component.html',
  styleUrls: ['./fields-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonTitle,
    IonContent,
    IonItem,
    IonInput,
    IonLabel,
    IonDatetimeButton,
    IonModal,
    IonDatetime,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonFooter,
    EntryParamsTextComponent,
    AddressPickFieldComponent,
    FormsModule,
    IonNote,
  ],
})
export class FieldsEditComponent implements OnInit {
  @Input() entry!: Entry;
  @Input() updatedData!: InfoForUpdate;
  @Input() isKeyboardOpen = false;
  @Input() edit = false;

  @ViewChild('datetime') dateTimeRef: any;

  workDays = 1;
  date?: Dayjs;
  calendarDate: string | undefined;
  unit: EnumEntryRequestsConditionsWorkType = 'shift';
  categoryParams: MachineTypeParams[] | null = [];
  paymentType: EnumEntryRequestsPaymentType = 'none';
  address: any;
  previousAddresses: any[] = [];
  price = 0;
  comment = '';
  objects: Maybe<EntryObjects>[] = [];
  entryParams: any[] = [];
  paymentTypeLabels = [
    { key: 'none', value: 'Любой' },
    { key: 'cashless_with_taxes', value: 'Безнал. НДС' },
    { key: 'cash', value: 'Наличные/карта' },
    { key: 'cashless', value: 'Безнал. без НДС' },
  ];

  constructor(
    private cd: ChangeDetectorRef,
    private loadingCtrl: LoadingController,
    private api: Api,
    private modalCtrl: ModalController,
  ) {}

  async ngOnInit() {
    this.setInitialData();
  }

  get category() {
    return this.entry.requests[0]?.data?.machineTypeData as MachineType;
  }

  get categoryName() {
    // @ts-ignore
    return translations[this.category?.name];
  }

  get getFilteredUnit(): string {
    switch (this.category?.unit?.[0]) {
      case 'shift':
        return 'Количество смен';
      case 'trip':
        return 'Количество рейсов';
      case 'cbm':
        return 'Количество кубов';
    }
    return '';
  }

  get startDate(): string {
    return this.date?.locale('ru').format('DD MMMM YYYY HH:mm') || 'Дата обсуждаема';
  }

  clearDate() {
    this.date = undefined;
  }

  get selectedParamsCount() {
    let setParamsCount = 0;
    if (this.categoryParams?.length && this.entryParams?.length) {
      this.entryParams.forEach((param) => {
        const categoryParam = this.categoryParams?.find((cp) => cp.uuid === param.refUuid);
        if (param.value && this.isParamSet(param, categoryParam)) {
          setParamsCount++;
        }
      });
    }
    return setParamsCount;
  }

  get getFilteredUnits(): any[] {
    const category = this.category;
    return unitOptions.filter((unit) => category?.unit?.find((u) => u === unit.id));
  }

  paymentTypeSelectedText() {
    if (this.paymentType !== 'none') {
      return this.paymentTypeLabels.find((type) => type.key === this.paymentType)?.value;
    } else return 'Способ оплаты: Любой';
  }

  setInitialData() {
    this.unit = this.updatedData.unit;
    this.workDays = this.updatedData.workDays;
    this.paymentType = this.updatedData.paymentType;
    this.price = this.updatedData.price;
    this.comment = this.updatedData.comment;
    this.objects = this.updatedData.objects;
    this.entryParams = this.updatedData.entryParams;
    this.date = this.updatedData.date;
    this.calendarDate = this.updatedData.calendarDate;
    this.address = this.updatedData.address;
    this.categoryParams = this.updatedData.categoryParams;
    this.previousAddresses = this.updatedData.previousAddresses;
    this.cd.markForCheck();
  }

  getUpdatedData(): InfoForUpdate {
    return {
      unit: this.unit,
      workDays: this.workDays,
      paymentType: this.paymentType,
      price: this.price,
      comment: this.comment,
      objects: this.objects,
      entryParams: this.entryParams,
      date: this.date,
      calendarDate: this.calendarDate,
      address: this.address,
      categoryParams: this.categoryParams,
      previousAddresses: this.previousAddresses,
    };
  }

  async openMachineParams() {
    const modal = await this.modalCtrl.create({
      component: EntryParamsComponent,
      componentProps: {
        category: this.category,
        params: this.entryParams,
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) this.entryParams = data;
    this.cd.markForCheck();
  }

  dateChange(e: any) {
    this.date = dayjs(e.detail.value).utc(true);
    this.cd.markForCheck();
  }

  isParamSet(param: any, categoryParam?: MachineTypeParams) {
    switch (categoryParam?.type) {
      case 'range':
        return (
          param.value?.[0] > categoryParam.settings.min ||
          param.value?.[1] < categoryParam.settings.max
        );
      case 'boolean':
        return !!param.value;
      case 'select':
        return param.value.length > 0;
    }

    return false;
  }

  reset() {
    this.date = undefined;
    this.cd.markForCheck();
  }

  async openMapForAddress() {
    const modal = await this.modalCtrl.create({
      component: AddressNewComponent,
      componentProps: {
        address: this.address,
        previousAddresses: [],
      },
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) this.pickAddress(data);
  }

  pickAddress(changes: any) {
    if (!changes) return;
    this.cd.markForCheck();
    this.address = {
      full: changes.fullAddress || changes.full,
      lat: changes.coordinates?.[1] || changes.lat,
      lng: changes.coordinates?.[0] || changes.lng,
      type: 'point',
    };
    if (changes.object) {
      this.objects[0] = changes.object;
    } else {
      this.objects[0] = {
        uuid: uuidGen.v4(),
        address: {
          fullAddress: this.address.full,
          coordinates: [this.address.lng, this.address.lat],
          type: 'point',
        },
      };
    }
  }

  clearAddress($event: any, i: number = 0) {
    this.objects.splice(i, 1);
  }
}
