<ion-header>
  <ion-toolbar>
    <ion-title>Адрес объекта</ion-title>
    <ion-buttons slot="start">
      <ion-button (click)="close()">
        <ion-icon name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <app-address-pick
    [addressOrig]="address"
    [previousAddresses]="previousAddresses"
    (entryAddressChanges)="setAddress($event)"
  ></app-address-pick>
</ion-content>
<ion-footer class="ion-no-border">
  <ion-button
    (click)="close(true)"
    class="ion-padding"
    expand="block"
    size="large"
    color="rcyellow"
  >
    Сохранить
  </ion-button>
</ion-footer>
