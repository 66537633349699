@if (user) {
  <div class="profile" tappable>
    <ion-avatar class="profile-avatar">
      @if (user.avatar) {
        <img src="{{ user.avatar }}?w=300&h=300" alt="avatar" />
      }
      @if (!user.avatar) {
        <img src="assets/avatar.svg" alt="avatar" />
      }
    </ion-avatar>
    <div class="header-content">
      <div class="profile-name">
        {{ user.firstname || user.lastname || "Без имени" }} {{ user.lastname }}
      </div>
    </div>
    <ion-buttons>
      <ion-button class="call-me" fill="outline" (click)="callToUs($event)">
        <ion-icon slot="icon-only" name="call"></ion-icon>
      </ion-button>
    </ion-buttons>
  </div>
}
