<ion-toolbar>
  <div class="addressLoading" [class.hidden]="!searchbarDisabled">
    <ion-spinner name="ios"></ion-spinner>
  </div>
  <app-address-pick-field
    (ngModelChange)="setNewAddress($event)"
    [ngModel]="address"
    [previousAddresses]="previousAddresses"
    placeholder="Адрес"
  ></app-address-pick-field>
</ion-toolbar>
<div class="map">
  @if (componentIsActive) {
  <ya-map
    [latitude]="address.lat"
    [longitude]="address.lng"
    [zoom]="zoom"
    [controls]="['geolocationControl']"
    (boundsChange)="mapChangeCenter($event)"
  ></ya-map>
  }
  <img class="marker" src="assets/icon/map-marker.svg" />
</div>
