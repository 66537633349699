import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MachineType } from '../../../base-types.gql-gen';
import { isDefined } from '../../utils';

@Component({
  selector: 'app-entry-params-text',
  templateUrl: 'entry-params-text.component.html',
  styleUrls: ['./entry-params-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class EntryParamsTextComponent implements OnChanges {
  @Input() category!: MachineType;
  @Input() params: { refUuid: string; value: any }[] = [];

  protected texts: string[] = [];

  constructor() {}

  ngOnChanges(_changes: SimpleChanges): void {
    this.updateParamTexts();
  }

  private updateParamTexts() {
    this.texts = [];

    for (const pval of this.params) {
      let txt = '';
      const pspec = this.category.params.find((x) => x?.uuid === pval.refUuid);
      if (!pspec) {
        continue;
      }
      if (pspec.type === 'boolean' && pval.value === true) {
        txt = pspec.nameRU;
      }
      if (pspec.type === 'range') {
        if (isDefined(pval.value?.[0])) {
          txt += ` от ${pval.value[0]}`;
        }
        if (isDefined(pval.value?.[1])) {
          txt += ` до ${pval.value[1]}`;
        }
        if (txt.length) {
          txt = `${pspec.nameRU}: ${txt.trim()}`;
          if (pspec.unit) {
            txt += ` ${pspec.unit}`;
          }
        }
      }
      if (pspec.type === 'select') {
        const labelByVal = new Map<string, string>();
        for (const opt of pspec.settings?.select ?? []) {
          labelByVal.set(opt.value, opt.name);
        }
        const abc: string[] = [];
        for (const x of pval.value ?? []) {
          const y = labelByVal.get(x);
          if (y) {
            abc.push(y);
          }
        }
        if (abc.length) {
          txt = `${pspec.nameRU}: ${abc.join(', ')}`;
        }
      }
      if (txt) {
        this.texts.push(txt);
      }
    }
  }
}
