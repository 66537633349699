<ion-item>
  <ion-input readonly="true" [value]="categoryName"></ion-input>
</ion-item>
<ion-item (click)="openMachineParams()" class="entry-field-row entry-field">
  @if (selectedParamsCount === 0) {
    <ion-label class="field-empty">Характеристики (необязательно)</ion-label>
  }
  @if (selectedParamsCount > 0) {
    <ion-label>
      <app-entry-params-text [category]="category" [params]="entryParams"></app-entry-params-text>
    </ion-label>
  }
  <ion-icon name="caret-down-sharp" slot="end" size="small"></ion-icon>
</ion-item>
<div class="entry-field-row--address">
  <app-address-pick-field
    [previousAddresses]="previousAddresses"
    [ngModel]="address"
    (ngModelChange)="pickAddress($event)"
    (ionClear)="clearAddress($event)"
  ></app-address-pick-field>
  <ion-icon (click)="openMapForAddress()" name="location-sharp" class="address-pin"></ion-icon>
</div>
<div class="entry-date">
  <ion-datetime-button datetime="date">
    <span slot="date-target" class="date-wrapper">{{ startDate }}</span>
  </ion-datetime-button>
  <ion-modal [keepContentsMounted]="true">
    <ng-template>
      <ion-datetime
        #datetime
        (ionChange)="dateChange($event)"
        [value]="calendarDate"
        id="date"
        preferWheel="true"
        presentation="date-time"
        minuteValues="0,15,30,45"
        mode="ios"
        locale="ru-RU"
      ></ion-datetime>
    </ng-template>
  </ion-modal>
  @if (!date) {
    <ion-icon name="calendar-outline" class="calendar"></ion-icon>
  }
  @if (date) {
    <ion-button (click)="reset()" size="small" fill="clear">
      <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
  }
</div>
@if (getFilteredUnit) {
  <ion-item class="entry-field">
    @if (getFilteredUnits.length === 1) {
      <ion-label class="entry-field units-info">{{ getFilteredUnit }}</ion-label>
    }
    @if (getFilteredUnits.length !== 1) {
      <ion-select
        class="custom-picker"
        [(ngModel)]="unit"
        placeholder="Тип расчета"
        disabled="{{ !edit }}"
        cancelText="Отмена"
      >
        @for (un of getFilteredUnits; track un) {
          <ion-select-option [value]="un.id">{{ un.name }}</ion-select-option>
        }
      </ion-select>
    }
    <ion-input
      class="custom-input work-days"
      [(ngModel)]="workDays"
      disabled="{{ !edit }}"
      slot="end"
      type="number"
      min="1"
    ></ion-input>
  </ion-item>
}

<ion-item class="entry-field-row entry-field">
  <ion-select
    [interfaceOptions]="{ header: 'Способ оплаты' }"
    class="custom-picker"
    [selectedText]="paymentTypeSelectedText()"
    [(ngModel)]="paymentType"
    disabled="{{ !edit }}"
    cancelText="Отмена"
  >
    @for (p of paymentTypeLabels; track p) {
      <ion-select-option [value]="p.key">{{ p.value }}</ion-select-option>
    }
  </ion-select>
</ion-item>
<div class="entry-field-row">
  <ion-item class="entry-field price" [class.disabled]="paymentType === 'none'">
    <ion-input
      class="custom-input entry-price"
      [(ngModel)]="price"
      [helperText]="paymentType === 'none' ? 'Для указания цены выберите способ оплаты.' : ''"
      placeholder="Бюджет (необязательно)"
      disabled="{{ !edit }}"
      type="number"
      min="100"
    ></ion-input>
  </ion-item>
</div>

<ion-item class="entry-field-row entry-field item-custom" no-lines>
  <ion-label position="floating">Комментарий</ion-label>
  <ion-textarea [(ngModel)]="comment" class="comment" name="comment"></ion-textarea>
</ion-item>
