<ion-searchbar
  #searchInput
  debounce="500"
  type="search"
  [searchIcon]=""
  [(ngModel)]="searchAddressText"
  (click)="searchClick()"
  (ionFocus)="searchFocusIn()"
  (ionClear)="handleClear($event)"
  (ionInput)="onInput()"
  [placeholder]="placeholder"
  class="address"
></ion-searchbar>
<div class="popper-dropdown" #prevAddressesContainer>
  @if (_suggestionsLoading) {
  <ion-spinner name="ios" class="popper-loader"></ion-spinner>
  }
  <ion-list class="prev-addresses-list">
    @if (suggestedObjects.length) {
    <span>Свои объекты</span>
    } @for (objects of suggestedObjects; track objects; let idx = $index) {
    <button
      ion-item
      [class.address__active]="_suggestionSelected === idx"
      (click)="setAddressAndClose(objects);"
    >
      {{objects.title}} {{ objects.full }}
    </button>
    } @if (suggestedAddresses.length) {
    <span>Поиск адреса</span>
    } @for (_address of suggestedAddresses; track _address; let idx = $index) {
    <button
      ion-item
      [class.address__active]="_suggestionSelected === idx"
      (click)="setAddressAndClose(_address);"
    >
      <p>{{_address.title}} {{ _address.full }}</p>
      <p class="info">{{ _address.geocodeValue}}</p>
    </button>
    }
  </ion-list>
</div>
