<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button [defaultHref]="'/'" (click)="close()"></ion-back-button>
    </ion-buttons>
    <ion-title>Характеристики</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <app-entry-params-list [params]="machineParams" [includeExtended]="false"></app-entry-params-list>
  @if (optionalEquipmentExists) {
    <h5 class="ion-padding-horizontal">Оборудование</h5>
    <app-entry-params-list
      [params]="machineParams"
      [includeExtended]="true"
    ></app-entry-params-list>
  }
</ion-content>
<ion-footer class="ion-no-border">
  <ion-button
    class="ion-padding"
    expand="block"
    size="large"
    color="rcyellow"
    (click)="close(true)"
  >
    Сохранить
  </ion-button>
</ion-footer>
