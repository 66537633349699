<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button [defaultHref]="'/'" (click)="back()"></ion-back-button>
    </ion-buttons>
    <ion-title>Информация о поставщике</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  @if (company) {
    <div class="main-card">
      <div class="main-card__header">
        <div>
          <div class="main-header" [class.not-verif]="!companyIsConfirmed">
            {{ company.name }}
            @if (company.uuid && companyIsConfirmed) {
              <ion-icon name="checkmark-circle" style="color: #20c389"></ion-icon>
            }
          </div>
          @if (companyIsConfirmed) {
            <div class="header-online">{{ companyRegisteredTimer }} на Rental Club</div>
          }
        </div>
        <div class="rc_logo">
          @if (company.uuid) {
            <img src="assets/rc.svg" />
          }
        </div>
      </div>
      <div class="text">
        <div class="info-box">
          <div>ИНН:</div>
          <div>{{ company.requisite?.inn || company.requisite?.info }}</div>
        </div>
        @if (company.date?.founded) {
          <div class="info-box">
            <div>Дата основания:</div>
            <div>{{ companyFoundation }}</div>
          </div>
        }
        @if (getManagmentPost) {
          <div class="info-box">
            <div>{{ getManagmentPost }}:</div>
            <div>{{ getManagmentName }}</div>
          </div>
        }
      </div>
    </div>
  }
  @if (!company && users) {
    <div class="main-card fis">
      <div class="main-card__header">
        <div>
          <div class="main-header">
            {{ users[0]?.user?.firstname }} {{ users[0]?.user?.lastname }} (физ. лицо)
          </div>
          <div class="header-online">{{ companyRegisteredTimer }} на Rental Club</div>
        </div>
        <img class="rc_logo" src="assets/rc.svg" />
      </div>
    </div>
  }
  @if (company && users) {
    <div class="workers">
      <ion-list-header no-lines class="list-title first-title" style="margin-bottom: 0">
        Сотрудники
      </ion-list-header>
      @for (user of users; track user) {
        <app-community-user-card [user]="user?.user"></app-community-user-card>
      }
    </div>
  }
  @if (machines && machines.length) {
    <div class="machines">
      <ion-list-header no-lines class="list-title second-title" style="margin-bottom: 0">
        Техника
      </ion-list-header>
      @for (category of machines; track category) {
        <div>
          <ion-list-header no-lines class="post-list">
            {{ getCategoryName(category.name) }}
          </ion-list-header>
          @for (machine of category.machines; track machine) {
            <app-provider-machine-list-card
              [machine]="machine"
              [myCompanyMachine]="false"
              tappable
            ></app-provider-machine-list-card>
            <app-machine-status-bar
              [machine]="machine"
              [showNoStatus]="false"
            ></app-machine-status-bar>
          }
        </div>
      }
    </div>
  }
</ion-content>
