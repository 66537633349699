import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Machine } from '../../../base-types.gql-gen';
import { IonAvatar, IonIcon } from '@ionic/angular/standalone';

@Component({
  selector: 'app-provider-machine-list-card',
  templateUrl: 'provider-machine-list-card.component.html',
  styleUrls: ['./provider-machine-list-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonIcon, IonAvatar],
})
export class ProviderMachineListCardComponent {
  @Input() machine!: Machine;
  @Input() myCompanyMachine = true;
}
