<ion-list no-margin>
  @for (param of filteredParams; track param.uuid) {
    <ion-item
      [class.invalid]="validateClass(param)"
      [class.item--range]="param.type === 'range'"
      [class.item--toggle]="param.type === 'boolean'"
      [class.item--select]="param.type === 'select'"
      lines="full"
    >
      @if (param.type === "range") {
        <ion-label class="item-label--range" position="floating">
          {{ param.nameRU }},
          <span class="param-value">
            {{ param.value.from || param.settings.min }} —
            {{ param.value.to || param.settings.max }}
          </span>
          {{ param.unit }}
        </ion-label>
      }
      @if (param.type === "boolean") {
        <ion-label class="item-label--toggle">
          {{ param.nameRU }}
        </ion-label>
      }
      @if (param.type === "select") {
        <ion-label class="item-label--select" position="stacked">
          {{ param.nameRU }}
        </ion-label>
      }
      @if (param.settings && param.type === "range") {
        <ion-range
          [min]="param.settings.min"
          [max]="param.settings.max"
          [dualKnobs]="true"
          [(ngModel)]="param.value"
          (ionChange)="updateRange($event, param)"
        >
          <div slot="start">{{ param.settings.min }}</div>
          <div slot="end">{{ param.settings.max }}</div>
        </ion-range>
      }
      @if (param.type === "boolean") {
        <ion-toggle mode="ios" [(ngModel)]="param.value"></ion-toggle>
      }
      @if (param.type === "select") {
        @for (option of paramsSelectOptions(param); track option) {
          <ion-item lines="full" class="custom-item--with-checkbox">
            <ion-checkbox
              slot="start"
              [checked]="isCheckedParamOption(param, option)"
              (ionChange)="toggleParamOption($event, param, option)"
            >
              {{ option.name }}
            </ion-checkbox>
          </ion-item>
        }
      }
    </ion-item>
  }
</ion-list>
