import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Machine } from '../../../base-types.gql-gen';
import dayjs from 'dayjs';

@Component({
  selector: 'app-machine-status-bar',
  templateUrl: 'machine-status-bar.component.html',
  styleUrls: ['./machine-status-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class MachineStatusBarComponent {
  @Input() machine!: Machine;
  @Input() showNoStatus = true;

  get getStatusDateTxt(): string {
    if (this.machineIsFree) return dayjs(this.machine.availabilityStatus?.statusEnds).fromNow(true);
    return '';
  }

  get machineIsFree(): boolean {
    return this.machine.availabilityStatus?.status === 'available';
  }

  get machineIsBusy(): boolean {
    return this.machine.availabilityStatus?.status === 'busy';
  }

  get getStatusText(): string {
    if (this.machineIsFree) return 'Свободна';
    if (this.machineIsBusy) return 'Занята';
    if (this.showNoStatus) return 'Без статуса';
    return '';
  }
}
